/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react";
import { useFormikContext } from "formik";
import { debounce } from "lodash";

import {
    useLazyGetAddressesQuery,
    useLazyGetPostcodesQuery,
} from "../../../services/dataService";
import { cleanSpaces, getDisplayAddress } from "../../../utils/dataUtils";
import { useModal } from "../../../hooks/modalsHooks";

import {
    Checkbox,
    DatePicker,
    SearchableSelect,
    SubmitButton,
    TextField,
} from "../../../components/forms";
import DropDown from "../../../components/dropDown/DropDown";
import SurgeryDropDown from "../../../components/surgeryDropDown/SurgeryDropDown";
import HelpText from "../../../components/HelpText";

import { DEBOUNCE_DELAY } from "../../../constants";

const gender = [
    { label: "MALE", value: 0 },
    { label: "FEMALE", value: 1 },
];

export default function UserDetailsForm() {
    const { setFieldValue, values } = useFormikContext();
    const { showModal } = useModal();

    const [postCodeOptions, setPostCodeOptions] = useState([]);
    const [addressList, setAddressList] = useState([
        { value: 0, label: "Enter address manually" },
    ]);
    const [selectedPostCode, setSelectedPostCode] = useState("");
    const [manualEntry, setManualEntry] = useState(false);
    const [isSurgerySelected, setSurgerySelected] = useState(false);
    const [surgeryManualEntry, setSurgeryManualEntry] = useState(false);

    const [getPostCodes, { isFetching: postCodeLoading }] =
        useLazyGetPostcodesQuery();
    const [getAddresses, { isFetching: addressLoading }] =
        useLazyGetAddressesQuery();

    const debouncedChangeHandler = useMemo(
        () => debounce((userInput) => getOptions(userInput), DEBOUNCE_DELAY),
        []
    );

    const getOptions = async (value) => {
        setFieldValue("address.line1", "");
        setFieldValue("address.line2", "");
        setFieldValue("address.city", "");
        setFieldValue("address.county", "");

        try {
            if (value) {
                const res = await getPostCodes({ partial: value });

                if (res.data) {
                    const transformedOptions = res.data.result?.map((optn) => ({
                        label: optn,
                        value: optn,
                    }));

                    setPostCodeOptions(transformedOptions);
                } else {
                    setPostCodeOptions([]);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getAddressList = async (value) => {
        setManualEntry(false);
        setSelectedPostCode(value);

        const postCode = value.replace(/ /g, "");

        try {
            if (value) {
                const res = await getAddresses({ postcode: postCode });

                if (res.data) {
                    const addressList = res.data.addresses.map((address, i) => {
                        const addr = address.split(",");
                        const result = {
                            line1: cleanSpaces(addr[0]),
                            line2: cleanSpaces(
                                addr[1] + " " + addr[2] + " " + addr[3]
                            ),
                            city: cleanSpaces(addr[5]),
                            county: cleanSpaces(addr[6]),
                            postcode: value,
                        };

                        return {
                            value: i + 1,
                            label: getDisplayAddress(result),
                            address: result,
                        };
                    });

                    setAddressList([
                        { value: 0, label: "Enter address manually" },
                        ...addressList,
                    ]);
                } else {
                    setPostCodeOptions([]);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleSelectAddress = (value) => {
        const selectedAddress = addressList.find(
            ({ label }) => label === value
        );

        if (selectedAddress.value === 0) {
            setManualEntry(true);
            setAddressList([{ value: 0, label: "Enter address manually" }]);
        } else {
            setManualEntry(false);
        }

        setFieldValue(
            "address",
            selectedAddress.address || {
                postcode: values.address.postcode,
            }
        );
        setPostCodeOptions([]);
    };

    const handleShowInfoModal = () => {
        showModal({
            modalType: "InfoModal",
            modalProps: {
                title: "Why do we need to know this?",
                body: (
                    <>
                        <p>
                            In short, we request your medical records to assess
                            your suitability for medical cannabis treatment.
                        </p>
                        <p>
                            With your permission, our doctors can access an
                            electronic summary without needing to request it
                            specifically from your GP, saving time and providing
                            a fuller picture of your medical history ahead of
                            your appointment.
                        </p>
                        <p>
                            We will never seek your information without your
                            explicit consent.
                        </p>
                        <p className="m-0">
                            If you need to discuss this further, please contact
                            us below.
                        </p>
                    </>
                ),
            },
        });
    };

    const handleSelectSurgery = (value, selected) => {
        setSurgerySelected(selected);
        setFieldValue("preferences.gpPractice", value.name);
        setFieldValue("preferences.gpAddress", value.address);
    };

    const handleClickSurgeryManualEntry = (input) => {
        setSurgeryManualEntry(input);
    };

    return (
        <>
            <div className="row mb-6">
                <div className="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
                    <TextField
                        name="firstName"
                        label={
                            <>
                                <label htmlFor="firstName" className="me-2">
                                    First Name*
                                </label>
                                <span className="text-primary-200 fs-6 fw-light">
                                    (as displayed on an ID)
                                </span>
                            </>
                        }
                        placeholder="Type your answer here..."
                    />
                </div>
                <div className="col-12 col-md-6 ps-md-6">
                    <TextField
                        name="lastName"
                        label={
                            <>
                                <label htmlFor="lastName" className="me-2">
                                    Last Name*
                                </label>
                                <span className="text-primary-200 fs-6 fw-light">
                                    (as displayed on an ID)
                                </span>
                            </>
                        }
                        placeholder="Type your answer here..."
                    />
                </div>
            </div>
            <div className="row mb-6">
                <div className="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
                    <SearchableSelect
                        label="Gender*"
                        searchable={false}
                        multiSelect={false}
                        dropDownOnly
                        fullWidth
                        name="gender"
                        defaultSelectPlaceholder="Type or select your answer"
                        options={gender}
                        showDownArrow={false}
                    />
                </div>
                <div className="col-12 col-md-6 ps-md-6">
                    <DatePicker name="dateOfBirth" label="Date of Birth*" />
                </div>
            </div>
            <div className="row mb-6">
                <div className="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
                    <SearchableSelect
                        label="Post code*"
                        name="address.postcode"
                        placeholder="Type your answer here..."
                        options={postCodeOptions}
                        fullWidth
                        multiSelect={false}
                        onChange={debouncedChangeHandler}
                        onSelect={getAddressList}
                        selectedValues={
                            selectedPostCode || values?.address.postcode
                        }
                        showOnMouseEnter
                        loading={postCodeLoading}
                        loadingText="Searching for postcodes, please wait..."
                        hideIcon
                    />
                </div>
                <div className="col-12 col-md-6 ps-md-6">
                    {!manualEntry || addressList.length !== 1 ? (
                        <DropDown
                            label="Address*"
                            placeholder="Select your answer here..."
                            options={addressList}
                            fullWidth
                            multiSelect={false}
                            onSelect={handleSelectAddress}
                            selectedValues={getDisplayAddress(
                                values?.address,
                                true
                            )}
                            loading={addressLoading}
                            loadingText="Searching for addresses, please wait..."
                            hideIcon
                        />
                    ) : (
                        <TextField
                            label="Address line 1*"
                            name="address.line1"
                            placeholder="Type your answer here..."
                        />
                    )}
                </div>
            </div>
            {manualEntry && addressList.length === 1 ? (
                <>
                    <div className="row mb-6">
                        <div className="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
                            <TextField
                                label="Address line 2"
                                name="address.line2"
                                placeholder="Type your answer here..."
                            />
                        </div>
                        <div className="col-12 col-md-6 ps-md-6">
                            <TextField
                                label="City*"
                                name="address.city"
                                placeholder="Type your answer here..."
                            />
                        </div>
                    </div>
                    <div className="row mb-6">
                        <div className="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
                            <TextField
                                label="County*"
                                name="address.county"
                                placeholder="Type your answer here..."
                            />
                        </div>
                        <div className="col-12 col-md-6 ps-md-6">
                            <TextField
                                label="Mobile number*"
                                name="phone"
                                type="tel"
                                placeholder="Type your answer here..."
                            />
                        </div>
                    </div>
                </>
            ) : (
                <div className="row mb-6">
                    <div className="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
                        <TextField
                            label="City*"
                            name="address.city"
                            placeholder="Type your answer here..."
                        />
                    </div>
                    <div className="col-12 col-md-6 ps-md-6">
                        <TextField
                            label="Mobile number*"
                            name="phone"
                            type="tel"
                            placeholder="Type your answer here..."
                        />
                    </div>
                </div>
            )}
            <div className="row mt-7 mb-6">
                <div className="col-12">
                    <label className="fs-5 fw-bold">Permissions</label>
                </div>
            </div>
            <div className="row mb-1">
                <div className="col-12">
                    <div className="mb-2">
                        <Checkbox
                            label="Yes, I agree to allow Treat It doctors access to my medical records for better care."
                            subtitle="Your consent ensures seamless and informed care."
                            name="preferences.accessNHSdetails"
                            className="check-lg"
                            labelClass="ms-3 fs-6 text-primary-200"
                            subtitleClass="ms-5 text-primary-100"
                        />
                    </div>
                    <div className="row ms-6">
                        <HelpText
                            className="mb-6"
                            text="Why do we need access to your medical records?"
                            onClick={handleShowInfoModal}
                        />
                    </div>
                </div>
            </div>
            <div className="row mb-5">
                <div className="col-12 mb-2">
                    <Checkbox
                        label="Yes, I'm comfortable sharing my GP details and for the Treat It clinic to reach out to my GP."
                        subtitle="Giving this consent ensures coordinated and comprehensive care for you. Rest assured your details are safe with us. We will never share your details with any 3rd parties."
                        name="preferences.shareDetailsWithGp"
                        className="check-lg"
                        labelClass="ms-3 fs-6 text-primary-200"
                        subtitleClass="ms-5 text-primary-100"
                    />
                </div>
            </div>

            {values?.preferences?.shareDetailsWithGp && (
                <>
                    <label className="text-primary fs-4 fw-bold mt-4 mb-2 text-start">
                        Your GP details
                    </label>
                    <p className="subtitle text-primary-200 text-start mb-7">
                        Please provide your GP’s details below
                    </p>

                    <div className="row mb-6">
                        <div className="col-12 col-md-6 pe-md-6 mb-7 mb-md-2 position-relative">
                            <SurgeryDropDown
                                onSelectSurgery={handleSelectSurgery}
                                onClickSurgeryManualEntry={
                                    handleClickSurgeryManualEntry
                                }
                                surgeryManualEntry={surgeryManualEntry}
                                name="preferences.gpAddress.postcode"
                            />
                        </div>
                        <div className="col-12 col-md-6 ps-md-6">
                            <TextField
                                name="preferences.gpPractice"
                                label="Practice name*"
                                placeholder="Type your answer here..."
                                disabled={
                                    !surgeryManualEntry && !isSurgerySelected
                                }
                            />
                        </div>
                    </div>
                    <div className="row mb-6">
                        <div className="col-12">
                            <TextField
                                name="preferences.gpAddress.line1"
                                label="Address Line 1*"
                                placeholder="Type your answer here..."
                                disabled={
                                    !surgeryManualEntry && !isSurgerySelected
                                }
                            />
                        </div>
                    </div>
                    <div className="row mb-6">
                        <div className="col-12">
                            <TextField
                                name="preferences.gpAddress.line2"
                                label="Address Line 2*"
                                placeholder="Type your answer here..."
                                disabled={
                                    !surgeryManualEntry && !isSurgerySelected
                                }
                            />
                        </div>
                    </div>
                    <div className="row mb-6">
                        <div className="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
                            <TextField
                                name="preferences.gpAddress.city"
                                label="Town*"
                                placeholder="Type your answer here..."
                                disabled={
                                    !surgeryManualEntry && !isSurgerySelected
                                }
                            />
                        </div>
                        <div className="col-12 col-md-6 ps-md-6">
                            <TextField
                                name="preferences.gpAddress.county"
                                label="County*"
                                placeholder="Type your answer here..."
                                disabled={
                                    !surgeryManualEntry && !isSurgerySelected
                                }
                            />
                        </div>
                    </div>
                </>
            )}

            <SubmitButton
                fullWidth={false}
                variant="primary mt-7 fs-5"
                type="submit"
            >
                <img
                    className="me-2 my-auto"
                    src="/images/vectors/arrow-right.svg"
                    alt=""
                />
                Next
            </SubmitButton>
        </>
    );
}
