import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import * as yup from "yup";

import { useModal } from "../../hooks/modalsHooks";

import { Form } from "../forms";
import Button from "../button/Button";
import HelpText from "../HelpText";
import GoBack from "../../components/GoBack";
import ProgressBar from "../progressBar/ProgressBar";

import "./question-container.scss";

export default function QuestionContainer({
    index,
    title,
    subTitle,
    hint,
    helpText,
    helpModal,
    helpModalBody,
    validation,
    children,
    initValues,
    onSubmit,
    prevQuestionAnswer,
    percentage
}) {
    const { showModal } = useModal();

    let [ displayPercentage, setDisplayPercentage ] = useState(0);
    let [ actualPercentage, setActualPercentage ] = useState(percentage);

    const handleShowInfoModal = () => {
        showModal({
            modalType: helpModal,
            modalProps: {
                title: "Why do we need to know this?",
                body: helpModalBody,
            },
        });
    };

    const questionTitle =
        title[prevQuestionAnswer] !== undefined
            ? title[prevQuestionAnswer]
            : title;

    const questionSubTitle =
        subTitle[prevQuestionAnswer] !== undefined
            ? subTitle[prevQuestionAnswer]
            : subTitle;

    const questionHint =
        hint[prevQuestionAnswer] !== undefined
            ? hint[prevQuestionAnswer]
            : hint;

    useEffect(() => {
        let roundPercentage = Math.round(percentage);
        setActualPercentage(percentage);

        const scrollPosition = window.scrollY;

        window.history.scrollRestoration = 'manual';
        window.scrollTo(0, scrollPosition);

        if(roundPercentage >= 100){
            roundPercentage = 100;
        }
        else if(roundPercentage <= 0){
            roundPercentage = 0;
        }

        setDisplayPercentage(roundPercentage);

        return () => {
            // Reset scroll restoration behavior when the component unmounts
            window.history.scrollRestoration = 'auto';
        };
        
    }, [percentage, setDisplayPercentage]);

    return (
        <div>
            {index > 1 && <div className="parent-progress-back row">
                <div className="progress-back">
                    <Link to={-1}>
                        <GoBack text="Back"/>
                    </Link>
                    <div className="w-50 progress-eligibility">
                        <ProgressBar progressPercentage={actualPercentage} />
                    </div>
                    <div className="p1 text-uppercase d-flex align-items-center fw-bold">
                        {Math.round(displayPercentage)}% Completed
                    </div>
                </div>
            </div>}

            <div className="question-container px-3 m-auto row">            
                {((index === 1 && initValues?.name) ||
                    (index > 1 && prevQuestionAnswer)) && (
                    <div className="d-flex">
                        <h2 className="text-primary me-2 me-sm-5 fw-bold d-none d-sm-block">
                            {index}.
                        </h2>
                        <div className="d-flex flex-column">
                            <h2 className="text-primary fw-bold mb-3 text-start">
                                <span className="d-block d-sm-none">
                                    {index}. {questionTitle}
                                </span>
                                <span className="d-none d-sm-block">
                                    {questionTitle}
                                </span>
                            </h2>
                            <p
                                className={`fs-5 subtitle text-primary-200 text-start ${
                                    hint || helpText ? "mb-3" : "mb-6"
                                }`}
                            >
                                {questionSubTitle}
                            </p>
                            {hint && (
                                <p className="fs-6 hint text-primary-100 text-start mb-6">
                                    {questionHint}
                                </p>
                            )}
                            {helpText && (
                                <HelpText
                                    className="mb-6"
                                    text={helpText}
                                    onClick={handleShowInfoModal}
                                />
                            )}
                            <Form
                                initialValues={initValues}
                                validationSchema={yup.object({ ...validation })}
                                onSubmit={onSubmit}
                                enableReinitialize
                            >
                                {children}
                                <Button
                                    fullWidth={false}
                                    variant="primary my-4 fs-5"
                                    type="submit"
                                >
                                    <img
                                        className="me-2 my-auto"
                                        src="/images/vectors/arrow-right.svg"
                                        alt=""
                                    />
                                    Next
                                </Button>
                            </Form>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
