import React from "react";
import { useNavigate } from "react-router-dom";
import TagManager from 'react-gtm-module';

import Button from "../../../components/button/Button";
import { GTM_ID } from "../../../constants/gtm";

const tagManagerArgs = {
    gtmId: GTM_ID
}

TagManager.initialize(tagManagerArgs)

export default function NameConfirmationPage() {

    window.dataLayer.push({
        event: 'Form submitted successfully'
    });
    
    const navigate = useNavigate();

    const savedValues = JSON.parse(
        localStorage.getItem("EligibilityForm") || "{}"
    );

    const handleNavigate = () => {
        navigate("/onboarding/eligibility/age");
    };

    return (
        <div className="landing-page px-3 my-auto">
            <div className="m-auto">
                <div className="d-flex mb-8">
                    <h1 className="mx-auto fw-bold text-primary">
                        Hi {savedValues?.name}!
                    </h1>
                </div>
                <div className="d-flex mb-8">
                    <p className="fs-5 subtitle text-primary-200 text-center mx-auto">
                        Thank you for joining us. To determine if our service is
                        right for you, we'd like you to complete a brief
                        eligibility questionnaire. This will only take a few
                        moments.
                    </p>
                </div>
                <div className="d-flex">
                    <Button
                        fullWidth={false}
                        variant="primary mx-auto fs-6"
                        onClick={handleNavigate}
                    >
                        Let's start
                    </Button>
                </div>
            </div>
        </div>
    );
}
