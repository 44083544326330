import * as yup from "yup";

export const ONBOARDING_ELIGIBILITY_QUESTIONS = {
    keys: ["name", "q1", "q2", "q3", "q4", "q5", "q6", "q7"],
    data: {
        q1: {
            index: 1,
            id: "q1",
            title: "Are you above the age of 21?*",
            subTitle:
                "You need to be 21 or over to join our Treat-It programme to qualify for medical cannabis.",
            hint: "",
            helpText: "",
            helpModal: "",
            helpModalBody: "",
            answers: [
                {
                    id: "q1_1",
                    name: "q1",
                    type: "checkbox",
                    label: "Yes, I'm older than 21",
                    placeholder: "",
                },
                {
                    id: "q1_2",
                    name: "q1",
                    type: "checkbox",
                    label: "No, I'm younger than 21",
                    placeholder: "",
                },
            ],

            validation: {
                q1: yup
                    .string()
                    .required("Please answer the question to continue"),
            },
            condition: ["q1_2"],
            conditionType: ["path"],
            conditionalPath: { q1_2: "eligibility/age/exit" },
            infoText: "",
            conditionalField: {
                type: "",
                label: "",
                name: "",
                placeholder: "",
            },
            paths: {
                prev: "eligibility/name",
                current: "eligibility/age",
                next: "eligibility/condition",
            },
        },
        q2: {
            index: 2,
            id: "q2",
            title: "What type of condition are you struggling with?*",
            subTitle:
                "Time to pick the type of condition you're dealing with. Let's get you the right help!",
            answers: [
                {
                    id: "q2_1",
                    name: "q2",
                    type: "checkbox",
                    label: "Pain",
                    placeholder: "",
                },
                {
                    id: "q2_2",
                    name: "q2",
                    type: "checkbox",
                    label: "Cancer Related Symptoms",
                    placeholder: "",
                },
                {
                    id: "q2_3",
                    name: "q2",
                    type: "checkbox",
                    label: "Mental Health",
                    placeholder: "",
                },
            ],
            hint: "",
            helpText: "",
            helpModal: "",
            helpModalBody: "",

            validation: {
                q2: yup
                    .string()
                    .required("Please answer the question to continue"),
            },
            condition: ["q2_1", "q2_2", "q2_3"],
            conditionType: ["info", "path"],
            conditionalPath: {
                q2_1: "eligibility/symptoms",
                q2_2: "eligibility/symptoms",
                q2_3: "eligibility/symptoms"
            },
            infoText: {
                q2_1: "Your pain may be related to these conditions: Migraine, Headache, Neuropathic Pain, Sciatica, Multiple Sclerosis, Restless Leg Syndrome, Cancer-Related Pain, Chronic Pain, Endometriosis, Fibromyalgia, Musculoskeletal Pain, and more.",
                q2_2: "Any type of cancer, and any symptoms from treatment of cancer, Nausea/Vomiting, Headaches, Pain, Loss of Appetite, Depression, Anxiety.",
                q2_3: "Mental health conditions include: ADHD, Autism Spectrum Disorder, Agoraphobia, Anxiety, Eating Disorders, Depression, Insomnia, Obsessive-Compulsive Disorder (OCD), Post Traumatic Stress Disorder (PTSD), Stress Disorders, Tourette’s Syndrome and more.",
            },
            conditionalField: {
                type: "",
                label: "",
                name: "",
                placeholder: "",
            },
            paths: {
                prev: "eligibility/age",
                current: "eligibility/condition",
                next: "eligibility/symptoms",
            },
        },
        q3: {
            index: 3,
            id: "q3",
            title: {
                q2_1: "What aspect of your pain affects you most?*",
                q2_2: "What specific cancer related symptom affects you most?*",
                q2_3: "What specific mental health symptom affects you most?",
            },
            subTitle: {
                q2_1: "Tell us how your pain is impacting your day to day life . This will assist us in our preliminary assessment.",
                q2_2: "Tell us symptom/s that affect you most frequently or severely. This will assist us in addressing your core pain issues.",
                q2_3: "Tell us how your mental health symptom is impacting your day to day life. This will assist us in our preliminary assessment.",
            },
            answers: [
                {
                    id: "q3_1",
                    name: "q3",
                    type: "text",
                    label: "",
                    placeholder: "Type your answer",
                },
            ],
            hint: {
                q2_1: "Please note pain is a symptom not a diagnosis.",
                q2_2: "",
                q2_3: "",
            },
            helpText: "",
            helpModal: "",
            helpModalBody: "",
            validation: {
                q3: yup
                    .string()
                    .required("Please answer the question to continue"),
            },
            condition: [],
            conditionType: [""],
            conditionalPath: {},
            infoText: "",
            conditionalField: {
                type: "",
                label: "",
                name: "",
                placeholder: "",
            },
            paths: {
                prev: "eligibility/condition",
                current: "eligibility/symptoms",
                next: "eligibility/diagnosis",
            },
        },
        q4: {
            index: 4,
            id: "q4",
            title: "Have you been diagnosed and prescribed any medications or treatments for this condition?*",
            subTitle:
                "Just a friendly heads-up - meeting the eligibility criteria often involves trying out at least two treatments or procedures for your condition.",
            answers: [
                {
                    id: "q4_1",
                    name: "q4",
                    type: "checkbox",
                    label: "Yes",
                    placeholder: "",
                },
                {
                    id: "q4_2",
                    name: "q4",
                    type: "checkbox",
                    label: "No",
                    placeholder: "",
                },
            ],
            hint: "",
            helpText: "Why do we need to know this?",
            helpModal: "InfoModal",
            helpModalBody:
                "If traditional treatments haven't effectively alleviated your symptoms, you could explore the use of medical cannabis. Although not typically the initial approach in the UK, it can be considered as an alternative option when conventional medications or treatments have failed to provide sufficient pain relief.",
            validation: {
                q4: yup
                    .string()
                    .required("Please answer the question to continue"),
            },
            condition: ["q4_2"],
            conditionType: ["path"],
            conditionalPath: { q4_2: "eligibility/diagnosis/exit" },
            infoText: "",
            conditionalField: {
                type: "",
                label: "",
                name: "",
                placeholder: "",
            },
            paths: {
                prev: "eligibility/symptoms",
                current: "eligibility/diagnosis",
                next: "eligibility/disorders",
            },
        },
        q5: {
            index: 5,
            id: "q5",
            title: "Have you ever received a diagnosis of Schizophrenia / Schizoaffective disorder, Psychosis or similar?*",
            subTitle:
                "If you've been diagnosed with these, it's important to note that safe medical practice may preclude being treated with medical cannabis. Your safety and compliance matter to us.",
            answers: [
                {
                    id: "q5_1",
                    name: "q5",
                    type: "checkbox",
                    label: "Yes",
                    placeholder: "",
                },
                {
                    id: "q5_2",
                    name: "q5",
                    type: "checkbox",
                    label: "No",
                    placeholder: "",
                },
            ],
            hint: "",
            helpText: "Why do we need to know this?",
            helpModal: "InfoModal",
            helpModalBody: (
                <>
                    <p>
                        It's important to note that cannabis may exacerbate
                        symptoms for individuals with certain conditions.
                        Therefore, in order to enroll in our treatment program,
                        we kindly request that you do not have a history of
                        Schizophrenia or Schizoaffective disorder.
                    </p>
                    <p className="m-0">
                        If you require further clarification or have any
                        questions, please don't hesitate to reach out to us
                        using the contact information provided below.
                    </p>
                </>
            ),
            validation: {
                q5: yup
                    .string()
                    .required("Please answer the question to continue"),
            },
            condition: ["q5_1"],
            conditionType: ["path"],
            conditionalPath: { q5_1: "eligibility/disorders/exit" },
            infoText: "",
            conditionalField: {
                type: "",
                label: "",
                name: "",
                placeholder: "",
            },
            paths: {
                prev: "eligibility/diagnosis",
                current: "eligibility/disorders",
                next: "eligibility/pregnant",
            },
        },
        q6: {
            index: 6,
            id: "q6",
            title: "Are you currently pregnant, intending to conceive or breastfeeding?*",
            subTitle:
                "In line with UK regulations, please be aware that we cannot prescribe medical cannabis if you are pregnant, intending to become pregnant, or breastfeeding. Your safety and the safety of your child are paramount to us.",
            answers: [
                {
                    id: "q6_1",
                    name: "q6",
                    type: "checkbox",
                    label: "Yes",
                    placeholder: "",
                },
                {
                    id: "q6_2",
                    name: "q6",
                    type: "checkbox",
                    label: "No",
                    placeholder: "",
                },
            ],
            hint: "",
            helpText: "",
            helpModal: "",
            helpModalBody: "",
            validation: {
                q6: yup
                    .string()
                    .required("Please answer the question to continue"),
            },
            condition: ["q6_1"],
            conditionType: ["path"],
            conditionalPath: { q6_1: "eligibility/pregnant/exit" },
            infoText: "",
            conditionalField: {
                type: "",
                label: "",
                name: "",
                placeholder: "",
            },
            paths: {
                prev: "eligibility/disorders",
                current: "eligibility/pregnant",
                next: "eligibility/location",
            },
        },
        q7: {
            index: 7,
            id: "q7",
            title: "Where do you currently live?*",
            subTitle:
                "Please note that our services are currently limited to specific regions due to varying legalities.",
            answers: [
                {
                    id: "q7_1",
                    name: "q7",
                    type: "checkbox",
                    label: "England",
                    placeholder: "",
                },
                {
                    id: "q7_2",
                    name: "q7",
                    type: "checkbox",
                    label: "Wales",
                    placeholder: "",
                },
                {
                    id: "q7_3",
                    name: "q7",
                    type: "checkbox",
                    label: "Scotland",
                    placeholder: "",
                },
                {
                    id: "q7_4",
                    name: "q7",
                    type: "checkbox",
                    label: "Northern Ireland",
                    placeholder: "",
                },
                {
                    id: "q7_5",
                    name: "q7",
                    type: "checkbox",
                    label: "Channel Islands",
                    placeholder: "",
                },
                {
                    id: "q7_6",
                    name: "q7",
                    type: "checkbox",
                    label: "Other",
                    placeholder: "",
                },
            ],
            hint: "",
            helpText: "",
            helpModal: "",
            helpModalBody: "",
            validation: {
                q7: yup
                    .string()
                    .required("Please answer the question to continue"),
                q7_6: yup.string().when("q7", {
                    is: "q7_6",
                    then: yup.string().required("Please specify other"),
                }),
            },
            condition: ["q7_6"],
            conditionType: ["field", "path"],
            conditionalPath: {
                q7_4: "eligibility/location/exit",
                q7_5: "eligibility/location/exit",
                q7_6: "eligibility/location/exit",
            },
            infoText: "",
            conditionalField: {
                type: "text",
                label: "Specify other*",
                name: "q7_6",
                placeholder: "Type your answer here...",
            },
            paths: {
                prev: "eligibility/pregnant",
                current: "eligibility/location",
                next: "eligibility/success",
            },
        },
    },
};
