import { useCallback, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { GoogleLogin } from "@react-oauth/google";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import { useAuth } from "../../../utils/authUtils";
import {
    useSignInMutation,
    useSocialSignInMutation,
} from "../../../services/authService";
import { useGetMyProfileQuery } from "../../../services/userService";
import parseError from "../../../utils/errorUtils";

import { SubmitButton, Form, TextField } from "../../../components/forms";

import { ACCESS_TOKEN_NAME } from "../../../constants";

import "../auth-page.scss";

const SignInSchema = yup.object({
    username: yup.string().email().required("Please enter your email"),
    password: yup.string().required("Please enter a password"),
});

export default function SignInPage() {
    const navigate = useNavigate();
    const auth = useAuth();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [initValues] = useState({
        username: "",
        password: "",
    });

    const [signIn] = useSignInMutation();
    const [signInWithSocial] = useSocialSignInMutation();
    const { data: profileData, refetch, isUninitialized } = useGetMyProfileQuery(
        {enabled: false},
        {
            skip: !auth.isLogged,
            refetchOnMountOrArgChange: true,
        }
    );

    const refetchProfile = useCallback(() => {
        if (!isUninitialized) {
            refetch();
        }
    }, [isUninitialized, refetch]);

    useEffect(() => {
        if (profileData) {
            if (profileData?.preferences?.TEMPORARY_APPOINTMENT) {
                navigate("../../onboarding/consultation/confirm-details");
            } else {
                navigate("/user/dashboard", { replace: true });
            }
        }
    }, [profileData]);

    const handleSignIn = async ({ username, password }) => {
        try {
            const captchaResponseToken = await handleReCaptchaVerify();

            if (captchaResponseToken) {
                const res = await signIn({
                    username,
                    password,
                    captchaResponseToken,
                });

                if (res.error) {
                    // handle error
                    if (res.error.data?.status === 412) {
                        toast.error(
                            "Your reCAPTCHA verification was failed. Please try again."
                        );
                    } else {
                        toast.error(parseError(res.error, "Couldn't sign in"));
                    }
                } else {
                    // request succeeded
                    await refetchProfile();
                    toast.success("Successfully signed in");

                    if (res.data.value) {
                        localStorage.setItem(ACCESS_TOKEN_NAME, res.data.value);
                        auth.logIn();
                    }
                }
            } else {
                toast.error("reCAPTCHA failed");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleSocialSignIn = async (values) => {
        try {
            const captchaResponseToken = await handleReCaptchaVerify();

            if (captchaResponseToken) {
                let data = {
                    captchaResponseToken,
                };

                const res = await signInWithSocial({
                    values: data,
                    credential: values.token,
                    provider: values.provider,
                });

                if (res.error) {
                    // handle error
                    if (res.error.data?.status === 412) {
                        toast.error(
                            "Your reCAPTCHA verification was failed. Please try again."
                        );
                    } else if (res.error.data?.status === 403) {
                        toast.error(
                            "Account not found. Please check eligibility to use our services."
                        );
                    } else if (res.error.data?.status === 409) {
                        toast.error(
                            "Provided email has been registered using a password. Please use that method to sign in."
                        );
                    } else {
                        toast.error(parseError(res.error, "Couldn't sign in"));
                    }
                } else {
                    // request succeeded
                    if (res.data.value) {
                        localStorage.setItem(ACCESS_TOKEN_NAME, res.data.value);
                        auth.logIn();
                        navigate("/user/dashboard", { replace: true });
                    }
                }
            } else {
                toast.error("reCAPTCHA failed");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleGoogleSignIn = (values) => {
        const data = {
            token: values.credential,
            provider: "GOOGLE",
        };

        handleSocialSignIn(data);
    };

    // const handleFacebookSignIn = (values) => {
    //     const data = {
    //         token: values.accessToken,
    //         provider: "FACEBOOK",
    //     };

    //     handleSocialSignIn(data);
    // };

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            return;
        }

        const token = await executeRecaptcha();
        return token;
    }, [executeRecaptcha]);

    return (
        <div className="sign-in-page row mx-0">
            <div className="px-0 px-sm-5 text-center">
                <div className="row mx-0 mb-5 justify-content-center">
                    <p className="my-auto w-fit">Don't have an account?</p>
                    <Link
                        to="/onboarding/eligibility/name"
                        className="link p-0 my-auto fw-normal w-fit"
                    >
                        Check eligibility
                    </Link>
                </div>
                <h2 className="mt-5 fw-semibold">Welcome</h2>
                <p className="mt-5 body body-small text-dark-5">
                    Login to use our services.
                </p>
            </div>
            {/* google */}
            <div className="d-flex align-content-center">
                <div className="mx-auto">
                    <GoogleLogin
                        onSuccess={handleGoogleSignIn}
                        onError={() => {
                            toast.error("Login failed");
                        }}
                        text="signin_with"
                        logo_alignment="center"
                        width="255"
                        shape="rectangular"
                        size="medium"
                    />
                </div>
            </div>
            {/* facebook */}
            {/* <div className="d-flex align-content-center mt-4">
                <div className="mx-auto">
                    <FacebookLogin
                        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                        callback={handleFacebookSignIn}
                        render={(renderProps) => (
                            <button
                                className="btn-social border-0 rounded-xs bg-fb text-white d-flex justify-content-center"
                                onClick={renderProps.onClick}
                                type="button"
                            >
                                <img
                                    src="/images/vectors/fb-logo.svg"
                                    alt=""
                                    className="my-auto rounded-circle"
                                    width={16}
                                />
                                <span className="ms-2 my-auto">
                                    Sign in with Facebook
                                </span>
                            </button>
                        )}
                        fields="email"
                        scope="email"
                    />
                </div>
            </div> */}
            <div className="auth-container mx-auto d-flex flex-column justify-content-center mb-12">
                <div className="py-6 px-12">
                    <hr className="hr-text" data-content="OR" />
                </div>
                <Form
                    onSubmit={handleSignIn}
                    validationSchema={SignInSchema}
                    initialValues={initValues}
                >
                    <div className="col-12 col-sm-7 col-lg-5 mb-3 mb-lg-0 mx-auto">
                        <div className="row mb-4">
                            <TextField
                                name="username"
                                placeholder="Email"
                                className="form-control-sm fs-6 px-5 py-4 bg-white"
                            />
                        </div>
                        <div className="row">
                            <TextField
                                name="password"
                                placeholder="Password"
                                type="password"
                                className="form-control-sm fs-6 px-5 py-4 bg-white"
                            />
                        </div>
                        <div className="row mx-0 mt-3 mb-8 justify-content-start">
                            <Link
                                to="/auth/forgot-password"
                                className="link p-0 my-auto fw-normal w-fit"
                            >
                                Forgot password?
                            </Link>
                        </div>
                        <SubmitButton variant="primary">Login</SubmitButton>
                    </div>
                </Form>
            </div>
        </div>
    );
}
