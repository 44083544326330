import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AppointmentContext } from "../../../../providers/AppointmentProvider";
import { RepeatOrderContext } from "../../../../providers/RepeatOrderProvider";
import useQuery from "../../../../hooks/useQuery";
import { useGetMyProfileQuery } from "../../../../services/userService";

import { Form } from "../../../../components/forms";
import GoBack from "../../../../components/GoBack";
import QuestionnaireForm from "./QuestionnaireForm";
import PrevPrescription from "../../../../components/prevPrescription/PrevPrescription";

const questionnaire = [
    {
        name: "q_1",
        text: "Have you been using your medication as prescribed?",
        type: "choice",
    },
    {
        name: "q_2",
        text: "Has there been a change in your medical condition since your last appointment?",
        type: "choice",
    },
    {
        name: "q_3",
        text: "Has the amount of pain medication you have been taking decreased since beginning your medical cannabis treatment (meaning, have you been consuming less than what was recommended)?",
        type: "choice",
    },
    {
        name: "q_4",
        text: "Have you started any new medication since your last appointment?",
        type: "choice",
    },
    {
        name: "q_5",
        text: "Have you been experiencing  any significant side effects (dizziness, anxiety, heart palpitations) since starting your medical cannabis treatment?",
        type: "choice",
    },
    {
        name: "q_6",
        text: "How would you rate your side effects?",
        type: "side_effect_modal",
    },
    {
        name: "q_7",
        text: "How do you currently rate your pain level?",
        type: "modal",
    },
    {
        name: "q_8",
        text: "Any other comments or concerns?",
        type: "choice",
    },
];

const mentalHealthQuestionnaire = [
    {
        name: "q_1",
        text: "Have you been using your medication as prescribed?",
        type: "choice",
    },
    {
        name: "q_2",
        text: "Has there been a change in your mental or physical state since your last appointment?",
        type: "choice",
    },
    {
        name: "q_3",
        text: "Has the amount of medication you have been using reduced since you've started your medical cannabis treatment?",
        type: "choice",
    },
    {
        name: "q_4",
        text: "Have you started any new medication since your last appointment?",
        type: "choice",
    },
    {
        name: "q_5",
        text: "Have you been experiencing any significant side effects (dizziness, anxiety, heart palpitations) since starting your medical cannabis treatment?",
        type: "choice",
    },
    {
        name: "q_6",
        text: "How would you rate your side effects?",
        type: "side_effect_modal",
    },
    {
        name: "q_7",
        text: "How do you currently rate your physical pain level?",
        type: "modal",
    },
    {
        name: "q_8",
        text: "During the last month, have you often been bothered by feeling down, depressed or hopeless?",
        type: "choice",
    },
    {
        name: "q_9",
        text: "Since your last consultation, have you had any suicidal thoughts?",
        type: "choice",
    },
    {
        name: "q_10",
        text: "How do you currently rate your general anxiety level?",
        type: "gad_modal",
    },
    {
        name: "q_11",
        text: "Any other comments or concerns?",
        type: "choice",
    }
];

export default function ReOrderQuestionnairePage() {
    const navigate = useNavigate();
    const query = useQuery();
    const type = query.get("type");
    
    const { data: profileData, isFetching: profileFetching } = useGetMyProfileQuery();

    const [showMentalHealthQuestionnaire, setShowMentalHealthQuestionnaire] = useState(false);

    const { setAppointmentData, appointmentData } = useContext(AppointmentContext);
    const { parentOrderId, availableToReorder } = useContext(RepeatOrderContext);

    const [initValues, setInitValues] = useState({
        q_1: { answer: "", reason: "" },
        q_2: { answer: "", reason: "" },
        q_3: { answer: "", reason: "" },
        q_4: { answer: "", reason: "" },
        q_5: { answer: "", reason: "" },
        q_6: { answer: "", reason: "" },
        q_7: { answer: "", reason: "" },
        q_8: { answer: "", reason: "" }
    });

    const [initMHValues, setInitMHValues] = useState({
        q_1: { answer: "", reason: "" },
        q_2: { answer: "", reason: "" },
        q_3: { answer: "", reason: "" },
        q_4: { answer: "", reason: "" },
        q_5: { answer: "", reason: "" },
        q_6: { answer: "", reason: "" },
        q_7: { answer: "", reason: "" },
        q_8: { answer: "", reason: "" },
        q_9: { answer: "", reason: "" },
        q_10: { answer: "", reason: "" },
        q_11: { answer: "", reason: "" }
    });

    const handleSubmit = (values) => {
        let questionAnswer = {};
        let questionnaireToFilter = showMentalHealthQuestionnaire ? mentalHealthQuestionnaire : questionnaire;

        questionnaireToFilter
            .filter(({ name }) => (name !== "q_7" || name !== "q_10" || name !== "q_6"))
            .forEach((data) => {
                questionAnswer[data.name] = {
                    question: data.text,
                    ...values[data.name],
                };
            });

        const pegForm = {
            ...(values.q_7?.answer || values.q_7),
        };

        const gadForm = {
            ...(values.q_10?.answer || values.q_10),
        };

        const sideEffectForm = {
            ...(values.q_6?.answer || values.q_6),
        };

        const data = {
            ...appointmentData,
            questionnaire: questionAnswer,
            pegForm,
            gadForm,
            sideEffectForm
        };

        setAppointmentData(data);

        if (!(availableToReorder && type === "REPEAT")) {
            navigate(`/appointment`);
        } else {
            navigate(`/appointment/change-prescription`);
        }
    };

    useEffect(() => {
        if (!showMentalHealthQuestionnaire && appointmentData?.questionnaire) {
            setInitValues({
                ...appointmentData?.questionnaire,
                q_7: appointmentData?.pegForm,
                q_6: appointmentData?.sideEffectForm
            });
        }

        if (showMentalHealthQuestionnaire && appointmentData?.mentalHealthQuestionnaire) {
            setInitMHValues({
                ...appointmentData?.mentalHealthQuestionnaire,
                q_7: appointmentData?.pegForm,
                q_6: appointmentData?.sideEffectForm
            });
        }
    }, [appointmentData]);

    useEffect(() => {
        // if (profileData?.medicalConditionType && profileData.medicalConditionType == 'Mental Health') {
        //     setShowMentalHealthQuestionnaire(true);
        // } else {
        //     setShowMentalHealthQuestionnaire(false);
        // }
        setShowMentalHealthQuestionnaire(true);
    }, [profileData]);

    return (
        <div className="row mx-0 pt-5 mb-5">
            <div className="col-12 col-lg-8 col-xl-6 mx-auto mb-12">
                <GoBack center="justify-content-center" />
                {availableToReorder && type === "REPEAT" && (
                    <>
                        <h2 className="text-center fw-bold mt-5">
                            REORDER YOUR LAST PRESCRIPTION
                        </h2>
                        <div className="mt-4 mb-5 mx-auto col-12">
                            <PrevPrescription orderId={parentOrderId} />
                        </div>
                    </>
                )}
                <div className="px-6 mb-10">
                    {availableToReorder && type === "REPEAT" && (
                        <>
                            <p className="body body-small text-dark-2 mb-3">
                                You are now able to reorder your last
                                prescription, as shown above. If your current
                                medication is out of stock, no need to worry, an
                                alternative medication of the same
                                strain/strength/administration route will be
                                recommended. All you will need to do is confirm
                                and accept it.
                            </p>
                            <p className="body body-small text-dark-2 mb-3">
                                Please note that upon completing this form, you
                                will be asked to pre-authorise a £9.90
                                prescription administration fee, which is in
                                addition to the cost of your medication. You
                                will not be charged until your prescription is
                                approved. Following pharmacy approval, you will
                                receive a separate payment link to pay for your
                                medication. Please be aware that medication
                                prices are set by the pharmacy and may have
                                changed since your last order.
                            </p>
                            <p className="body body-small text-dark-2 mb-5">
                                If you’d like to request changes to your
                                prescription, or if your situation has changed,
                                you may be asked to book another appointment
                                with the clinic.
                            </p>
                        </>
                    )}
                    <p
                        className={`body body-small fw-semibold text-dark ${
                            !(availableToReorder && type === "REPEAT") &&
                            "text-center mt-5"
                        }`}
                    >
                        Please answer the following questions to proceed
                    </p>
                    <div className="d-flex justify-content-center mt-5">
                        <div className="mx-auto">
                            <Form
                                onSubmit={handleSubmit}
                                initialValues={showMentalHealthQuestionnaire ? initMHValues : initValues}
                                enableReinitialize
                            >
                                <QuestionnaireForm
                                    questionnaire={showMentalHealthQuestionnaire ? mentalHealthQuestionnaire : questionnaire} 
                                    isMentalHealthQuestionnaire={showMentalHealthQuestionnaire}
                                />
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
