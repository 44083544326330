import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import dayjs from "dayjs";

import {
    useGetMyProfileQuery,
    useUpdateProfileMutation,
} from "../../../services/userService";
import parseError from "../../../utils/errorUtils";

import { Form, SubmitButton } from "../../../components/forms";
import BasicInfoForm from "../../../components/basicInfoForm/BasicInfoForm";
import GoBack from "../../../components/GoBack";

const ChangeBasicInfoSchema = yup.object({
    firstName: yup.string().required("Please enter your first name"),
    lastName: yup.string().required("Please enter your last name"),
    dateOfBirth: yup
        .date()
        .required("Please enter your date of birth")
        .test(
            "dateOfBirth",
            "Should be at least 21 years old",
            function (value) {
                return dayjs(value).diff(new Date()) <= -662695446000;
            }
        ),
    gender: yup.string().required("Please select your gender"),
    phone: yup
        .string()
        .required("Please enter your mobile number")
        .matches("^[+]?[0-9]{8,15}$", "Please enter a valid mobile number"),
    address: yup.object({
        postcode: yup
            .string()
            .required("Please select a postcode from the list"),
    }),
});

export default function BasicInfoPage() {
    const navigate = useNavigate();

    const [initValues, setInitValues] = useState({
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        gender: "",
        address: {
            postcode: "",
        },
        phone: "",
    });

    const [updateProfile] = useUpdateProfileMutation();
    const { data: profileData } = useGetMyProfileQuery();

    const handleSubmit = async (values) => {
        try {
            const data = {
                ...profileData,
                ...values,
                dateOfBirth: profileData.dateOfBirth,
            };

            const postCode = data.address?.postcode;

            if (postCode.slice(0, 2).toLowerCase().includes("bt")) {
                toast.error("Location not currently supported");
            } else {
                const res = await updateProfile({ values: data });

                if (res.error) {
                    // handle error
                    toast.error(
                        parseError(res.error, "Couldn't update profile")
                    );
                } else {
                    // request succeeded
                    toast.success("Profile successfully updated");
                    navigate("/user/account");
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (profileData) {
            const values = {
                firstName: profileData.firstName,
                lastName: profileData.lastName,
                dateOfBirth: profileData.dateOfBirth,
                gender: profileData.gender,
                address: profileData.address,
                phone: profileData.phone,
            };

            setInitValues(values);
        }
    }, [profileData]);

    return (
        <div className="account-layout pt-6 pt-lg-0 mb-4 mb-sm-6 mb-lg-0 px-xl-6 px-xxl-12">
            <GoBack text="Go back to account" />
            <div className="description-section bg-white rounded mt-5 px-5 py-7">
                <h3 className="text-primary text-center fw-bold">
                    Basic Information
                </h3>
                <Form
                    onSubmit={handleSubmit}
                    initialValues={initValues}
                    validationSchema={ChangeBasicInfoSchema}
                    enableReinitialize
                >
                    <div className="col-12 col-sm-7 col-md-8 col-lg-6 col-xl-5 mb-3 mb-lg-0 mx-auto mt-8">
                        <BasicInfoForm
                            disabled={{
                                firstName: true,
                                lastName: true,
                                gender: true,
                                dateOfBirth: true,
                            }}
                        />
                        <div className="row mx-0 mt-7">
                            <SubmitButton variant="primary text-uppercase px-2">
                                Save Changes
                            </SubmitButton>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
}
