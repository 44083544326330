import React from "react";
import { Link } from "react-router-dom";

import { GPS_URL } from "../../../constants";
import GoBack from "../../../components/GoBack";

export default function EligibilityAgeExitPage() {
    return (
        <div className="age-exit-page px-3 m-auto">
            <div className="d-flex flex-column">
                <Link to={-1}>
                    <GoBack text="Back"/>
                </Link>
                <h2 className="text-primary fw-bold mb-6 text-center">
                    Not Quite There Yet!
                </h2>
                <p className="fs-5 subtitle text-primary-200 text-center mb-10">
                    If you are under 21 and experiencing pain, we understand the
                    importance of seeking medical support. Unfortunately we
                    cannot provide consultations to those under 21. However, we
                    would still like to help guide your health journey.
                </p>
                <div className="d-flex flex-column shadow-sm justify-content-center mx-auto px-3 px-sm-8 py-8 card border-0 rounded-1 w-100 bg-white bg-opacity-50">
                    <div className="mb-6 mx-auto">
                        <img
                            src="/images/vectors/discover-gps.svg"
                            alt=""
                            className="img-fluid"
                        />
                    </div>

                    <p className="fs-5 text-primary-200 text-center mb-6">
                        If you're between 18 and 21 years old, we may still be
                        able to assist you. Click the link below to access our
                        general practice platform, where you can speak with a
                        doctor to see if they can help with your condition.
                    </p>

                    <div className="button-container mx-auto">
                        <a
                            className="btn btn-primary text-start mb-4 fw-light"
                            href={GPS_URL}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <div className="d-flex">
                                <img
                                    className="me-2 my-auto"
                                    src="/images/vectors/arrow-right.svg"
                                    alt=""
                                />
                                Go to The GP Service
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
