import React from "react";

import {
    SUPPORT_EMAIL,
    SUPPORT_TELEPHONE,
} from "../../constants/appProperties";

import "./cookies-policy.scss";

function PrivacyPolicyPage() {
    return (
        <div className="policy-container container my-6 pb-6">
            <h1 className="fw-bold fs-3 mb-6">Privacy Policy</h1>
            <p>
                This privacy notice provides information on the Treat It System
                uses personal data relating to users (“you“) through its website
                at{" "}
                <a
                    href="https://treat-it.clinic/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    https://treat-it.clinic
                </a>{" "}
                , its mobile and tablet applications and certain software
                (together, the “Treat It System”).
            </p>
            <p>
                Please read this privacy notice carefully to understand our
                views and practices regarding your personal data, how we will
                treat it, your rights and how to contact us.
            </p>
            <p>
                BY USING THE TREAT IT SYSTEM, AND BY GIVING YOUR INFORMATION TO
                US, YOU INDICATE YOUR CONSENT TO US AND THIRD PARTIES COLLECTING
                AND USING YOUR PERSONAL INFORMATION IN ACCORDANCE WITH THE TERMS
                SET OUT IN THIS NOTICE.
            </p>
            <p>
                This privacy notice forms part of Treat It’s Terms and
                Conditions at{" "}
                <a
                    href="https://treat-it.clinic/privacy-policy/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    https://treat-it.clinic
                </a>
                . You may print a copy for your records and future reference.
            </p>

            <h6 className="mt-6">1. What is Treat It? </h6>
            <p>
                Treat It (“The Clinic”, “we,” or “us”) is part of The GP Service
                and brand name trademarked under Kanabo Group PLC, a company
                incorporated in England and Wales under company number
                338440891.
            </p>
            <p>
                If you have any queries in relation to the processing of your
                personal data by the Treat It System Limited, please contact us:
            </p>
            <ul>
                <li>
                    <span className="fw-bold">By post: </span> Treat It, c/o the
                    GP Service (UK) Limited, Coventry University Technology
                    Park, The TechnoCentre, Puma Way, COVENTRY, CV1 2TT
                </li>
                <li>
                    <span className="fw-bold">By email: </span> {SUPPORT_EMAIL}
                </li>
                <li>
                    <span className="fw-bold">By phone: </span> Please ask for
                    the Admin Team between the hours of 9.30am and 5.00pm Monday
                    to Thursday on {SUPPORT_TELEPHONE}.
                </li>
            </ul>
            <p>
                We have a Privacy Officer who is responsible for enforcing our
                policy and making sure everyone at Treat It respects our privacy
                policy. Should you have any questions, comments or suggestions,
                they will be happy to hear from you. You can email them at
                privacy@treat-it.clinic.
            </p>
            <p>
                If you have any complaints, you can also contact the ICO
                (Information Commissioners Officer). Our ICO reference is
                ZA194910.
            </p>
            <p>
                Treat It provides an online service that brings together
                patients, doctors and pharmacies. It provides this service and
                related services (together the "Services") via the Treat It
                System.
            </p>
            <p>
                The Treat It System acts as a data controller in its use of your
                personal data, as described in this privacy notice.
            </p>
            <p>
                You can contact Treat It by using the contact details set out
                above.
            </p>

            <h6 className="mt-7">
                2. Collection of data by the Treat It System
            </h6>
            <h6 className="mt-6">2.1. Registration details</h6>
            <p>
                If you use the Treat It System or install any of Treat It’s
                mobile or tablet applications, you will be asked to register
                with the Treat It System and provide certain information in
                order to create an account and receive the Services.
            </p>
            <p>Registration information may include:</p>
            <ul>
                <li>your name, telephone number and email address;</li>
                <li>your postal address;</li>
                <li>
                    the telephone number of the mobile device on which you have
                    installed the Treat It’s mobile application or use Treat Its
                    web service; and
                </li>
                <li>
                    your date of birth (which will be used to check you are over
                    18 years of age and as an additional identity check should
                    you need to get in contact with Treat It).
                </li>
                <li>Details of your regular NHS GP.</li>
            </ul>

            <h6 className="mt-6">
                2.2. Personal data collected when you use the Services
            </h6>
            <p>
                Personal data is any information that is related to a person
                from which that can be either directly or indirectly identified.
                This can be any information related to you like your name and
                surname or address.
            </p>

            <h6 className="mt-6">
                2.2.1 When you use any one or more of the Services, we may also
                ask you for and/or collect:
            </h6>
            <ul>
                <li>
                    your payment card details (which will be used for the
                    purposes of paying for your order but will not be stored by
                    us);
                </li>
                <li>
                    medical information about you (for example, any illnesses,
                    medical history, prescriptions provided);
                </li>
                <li>
                    data relating to the Products and Services you purchase via
                    the Treat It System; and
                </li>
                <li>
                    a record of any consultations you have with a doctor via the
                    Treat It System, together with details of the care, advice
                    and / or treatment that you receive from any such doctor.
                </li>
            </ul>

            <h6 className="mt-6">
                2.2.2 Summary Care Record (SCR) and NHS Number.
            </h6>
            <p>
                We will need to access the Personal Demographics Service (PDS)
                which is the national electronic database of NHS patient details
                such as name, address, date of birth and NHS Number (known as
                demographic information). Each individual record on the PDS
                contains identifiable data. The data items held include NHS
                Number, name, date of birth, gender, GP practice, address(es)
                and contact details (such as telephone numbers and email
                addresses) amongst other relevant details. Data is also held,
                where applicable, on certain patient preferences such as
                nominated pharmacy and whether the record is marked as
                ‘sensitive’.
            </p>
            <p>
                NHS Digital operates PDS as part of the Spine under direction
                from the Secretary of State for Health and is the Data
                Controller. PDS serves as the register of patients registered
                for, or otherwise in receipt of, health and care services
                commissioned by NHS organizations in England and Wales.
            </p>
            <p>
                Any medical information that we collect about you is categorised
                as sensitive personal data under the Data Protection Act 2018
                and the General Data Protection Regulation (GDPR) introduced on
                25th May, 2018.
            </p>

            <h6 className="mt-6">2.3 Contacting Treat It</h6>
            <p>
                If you contact Treat It in relation to any of the Services or
                any part of the Treat It System (via email, telephone, post, or
                otherwise), we may collect and retain your contact details
                (name; email address; phone number) and your communication for
                the purpose of handling your query and keeping records of
                communications. Examples of when we collect such data is when
                you:
            </p>
            <ul>
                <li>
                    complete any forms or medical questionnaires on our website
                </li>
                <li>register and create your account to order from us</li>
                <li>enter a voucher or promotion</li>
                <li>report a problem with our site</li>
                <li>make contact with our support team</li>
                <li>
                    take part in a voluntary survey or perhaps write a review of
                    our service
                </li>
                <li>
                    subscribe to a specific marketing offer, information or
                    newsletters
                </li>
            </ul>

            <h6 className="mt-6">2.4 Data we receive from other sources</h6>
            <p>
                We work with third parties such as pharmacies and
                sub-contractors in technical, payment processing using
                organizations such as stripe, working with advertising
                partners/providers for analytics, obtaining credit and search
                references from agencies and information providers which may
                provide us with information about you.
            </p>
            <p>
                We also work closely with other third parties (including, for
                example, business partners, sub-contractors in technical,
                payment and delivery services, advertising networks, analytics
                providers, search information providers, credit reference
                agencies), and we may sometimes receive information about you
                from them.
            </p>
            <p>
                All such information may be combined with the data you provide
                to us when you register with Treat It and / or when you use the
                Services, and the combined data may be used by us for the same
                purposes outlined in this notice.
            </p>

            <h6 className="mt-6">
                2.5. Data we collect from your visit to our service using a
                computer, mobile phone or other communication device{" "}
            </h6>
            <p>
                When you use our service, we may also collect information about
                how you use our website. In order for us to improve our website
                and mobile applications. Examples of the type of information we
                may collect include:
            </p>
            <ul>
                <li>the device you use (for example iphone, android)</li>
                <li>
                    your device’s unique identifier (for example your device’s
                    IMEI number for mobiles or the MAC address of the device’s
                    wireless network interface for a computer)
                </li>
                <li>
                    any network related information (e.g. 02 network, Virgin
                    Broadband)
                </li>
                <li>technical device information</li>
                <li>your operating system (e.g Windows, Mac OS,)</li>
                <li>your IP address and related information</li>
                <li>
                    your location and the time zone you are in (e.g if you are
                    in or outside the UK)
                </li>
                <li>
                    your Treat It login information related to your account with
                    us
                </li>
                <li>
                    the type of browser you may be using (for example Chrome )
                </li>
                <li>
                    The phone number you dialed to contact our customer support
                    team
                </li>
                <li>
                    websites you have looked at before and after our own
                    website,
                </li>
                <li>sites you have searched for or visited</li>
                <li>
                    time spent on individual pages or how you browse away from
                    the page, and how you interact with our website (scrolling,
                    clicks, etc);
                </li>
            </ul>

            <h6 className="mt-6">
                2.6 Your agreement with us to provide true and accurate
                information
            </h6>
            <p>
                By using our services, you are agreeing, at all times, to
                provide us with accurate and complete information when
                completing any information on our service including in your
                registration and account, and to update such information as
                appropriate. The services and advice provided to you when you
                use our service is based on the information you have supplied.
                It is your responsibility to ensure this information is accurate
                and complete and you accept that failure to do so (whether
                intentionally or not) will affect the advice given to you and
                any medicines prescribed to you and, as such, may have very
                serious consequences for which neither we or any doctor can be
                responsible for.
            </p>

            <h6 className="mt-7">3. How we use your data</h6>
            <h6 className="mt-6">
                3.1. We use the data that you provide to us when you register
                with Treat It, and the data that we collect when you use the
                Services or contact us, as follows:
            </h6>
            <ul>
                <li>
                    to provide you with the Services that you request from us in
                    accordance with our obligations under any agreement entered
                    into with you;
                </li>
                <li>
                    to communicate with you in the event that any services
                    requested are unavailable or if there is a query or problem
                    with your order;
                </li>
                <li>for record keeping purposes;</li>
                <li>
                    where you have provided your consent, your name, email
                    address and contact number may be used to provide you, or
                    permit selected third parties to provide you, with
                    information about goods or services we feel may interest you
                    (you may unsubscribe from this at any time by contacting us
                    using the contact details in section 10 below);
                </li>
                <li>
                    to ensure that content on the Treat It System is presented
                    in the most effective manner for your device;
                </li>
                <li>
                    to take payment for the Services and/or Products you
                    purchase via the Treat It System;
                </li>
                <li>to monitor and improve our service</li>
                <li>
                    to notify you about changes to our Services or to the Treat
                    It System
                </li>
                <li>for legal, compliance and regulatory reasons.</li>
            </ul>
            <p>We may also use and disclose your personal data:</p>
            <ul>
                <li>to track and analyse activity on the Treat It System:</li>
                <li>
                    for internal operations, including troubleshooting, data
                    analysis, testing, measuring advertising effectiveness,
                    research and/or survey purposes;
                </li>
                <li>
                    to perform a range of other business intelligence functions
                    to optimise the Services and introduce new offerings;
                </li>
                <li>
                    to check your compliance with Treat It’s Terms and
                    Conditions at{" "}
                    <a
                        href="https://treat-it.clinic/terms-conditions/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        treat-it.clinic/terms
                    </a>{" "}
                </li>
                <li>
                    for the administration and maintenance of the Treat It
                    System and the Services;
                </li>
                <li>
                    for compliance with legal obligations, or protection and
                    enforcement of legal rights; and/or
                </li>
                <li>to detect or prevent fraudulent activity.</li>
            </ul>

            <p>
                For providing our services and arranging for any medicines to be
                delivered to you{" "}
            </p>
            <p>We use your personal information to:</p>
            <ul>
                <li>
                    provide a consultation with one of our doctors or to check
                    your suitability for a particular treatment or treatment for
                    a presenting complaint for which you are consulting with one
                    of our doctors.
                </li>
                <li>
                    recommend from your responses to any medical questionnaire,
                    a particular treatment
                </li>
                <li>
                    deliver your prescription to your chosen pharmacy or
                    medication(s) to your address as maybe applicable
                </li>
                <li>provide any test kit results to you</li>
                <li>
                    confirm your identity and access your medical record and
                    account (if necessary) when you call our customer support
                    team.
                </li>
                <li>
                    provide any advice and useful information related to your
                    enquiry or concern
                </li>
                <li>
                    access your Summary Care Record (SCR) on the NHS database
                    where you have provided consent for us to do so.
                </li>
            </ul>
            <p>For research purposes</p>
            <p>We use your personal information to:</p>
            <ul>
                <li>carry out a range of market research</li>
                <li>analyse both individual and collective data</li>
                <li>make improvements to our services</li>
                <li>
                    send you questionnaires or surveys related to our services
                </li>
                <li>
                    offer you services or provide tools to interact with us on
                    our website
                </li>
            </ul>

            <p>Additional processing</p>
            <p>
                We will always ask for your consent prior to the use of any data
                for research or marketing or other purposes, unless the
                information we are using could in no way identify you (for
                example: a male, living in a location between the ages of 21 to
                30.
            </p>
            <p>For legal, compliance, security, and regulatory reasons</p>
            <p>We use your personal information to:</p>
            <ul>
                <li>
                    detect and prevent fraud. Treat It needs to make sure that
                    you are who you say you are to deliver our services to you
                    safely, and we also need to ensure that no fraud is
                    happening on our website like payment with a stolen payment
                    card
                </li>
                <li>
                    comply with any applicable law, regulation, legal process or
                    public authorities request.
                </li>
                <li>
                    defend our rights, property and safety, as required or
                    permitted by law
                </li>
            </ul>

            <p>
                We may also need to share your personal information for legal
                reasons:
            </p>

            <ul>
                <li>
                    should we sell or buy any business or assets, as we may need
                    to share your data with the future seller or buyer
                </li>
                <li>
                    to protect the rights, property, or safety of Treat It, our
                    patients, suppliers and partners, or others. This includes
                    exchanging information for fraud protection, reducing credit
                    risk and verifying your identity by a third-party identity
                    checking provider
                </li>
                <li>
                    if we need to share your personal data as a result of a
                    court order or any other legal obligation
                </li>
            </ul>

            <h6 className="mt-6">3.1 How long we hold personal information.</h6>
            <p>
                The service takes into account relevant guidance, including that
                from the Care Quality Commission’s Schedule of Applicable
                Guidance.
            </p>
            <ul>
                <li>All records will be held securely.</li>
                <li>
                    Access will be restricted to persons who have a need to
                    access such documents as appropriate (e.g. Registered
                    Person(s) or where relevant, practitioners).
                </li>
                <li>
                    The records will be maintained for a minimum of 11 years
                    from the date of last entry where patient details are
                    contained within the documentation. All records will be
                    destroyed by shredding after the retention period.
                </li>
            </ul>
            <p>
                Patient records will be kept for the time period required under
                relevant legislation.
            </p>

            <h6 className="mt-7">4. Sharing your data</h6>
            <p>
                Treat It will never sell information that can be used to
                personally identify you to a third party.
            </p>
            <p>
                Our website may contain links to and from the websites of our
                partner networks, advertisers and affiliates/resellers. If you
                follow a link, please be aware that each site will have their
                own privacy policy/notice, for which we have no responsibility
                or liability. We advise that you check any privacy notice before
                you provide any personal information or date to any third-party
                site.
            </p>
            <p>
                However, you agree that Treat It may share and disclose your
                personal data for the purposes outlined at Section 3 above to
                third parties, including your personal data with third parties
                as follows:
            </p>

            <h6 className="mt-6">4.1. Disclosure to doctors, pharmacies</h6>

            <p>Treat It may share and disclose:</p>

            <ul>
                <li>
                    your personal data (including any medical information you
                    provide to us) with the doctor allocated to you to carry out
                    a consultation for the purposes of enabling the relevant
                    doctor to provide you with medical services, a diagnosis and
                    treatment;
                </li>
                <li>
                    your personal data (including any medical information you
                    provide to us and any medical information that is generated
                    through your use of the Services such as a record of any
                    consultations you have with a doctor via the Treat It
                    System, together with details of the care, advice and / or
                    treatment that you receive from any such doctor) to your NHS
                    General Practitioner (“GP“). This is a compliance
                    requirement of the General Medical Council, and;
                </li>
                <li>
                    your basic identification data with the pharmacy for the
                    purposes of enabling the pharmacy to verify your
                    identification on collection of your prescription or deliver
                    your prescription to you.
                </li>
            </ul>

            <h6 className="mt-6">4.2. Disclosure to other third parties</h6>
            <p>
                Treat It may share and disclose your personal data for the
                purposes outlined at Section 3 above to third parties,
                including:
            </p>

            <ul>
                <li>our service providers and professional advisers:</li>
                <li>The nominated pharmacy </li>
                <li>‘Know Your Customer’ checks</li>
                <li>NHS Digital: Summary Care Records</li>
                <li>The nominated GP: patient records</li>
                <li>other third parties where required or permitted by law.</li>
            </ul>

            <h6 className="mt-6">4.2.1 Marketing Information</h6>
            <p>
                Where you have opted in to receive marketing information, your
                data may also be passed to third parties including Mail Chimp
                and Trustpilot.
            </p>
            <p>
                Data passed to these third parties for marketing purposes,
                includes:
            </p>
            <ul>
                <li>Title, First name, Last name, Email address</li>
            </ul>
            <p>
                This data will be stored for email marketing purposes only and
                will not sell or share your personal data.
            </p>
            <p>
                In these circumstances, we will ensure that personal information
                is properly protected and that it is only used in accordance
                with this privacy notice. You can ask to stop receiving all or
                part of this information at any time.
            </p>

            <h6 className="mt-6">
                4.3 Disclosure for the purposes of law and enforcement
            </h6>
            <p>
                Where we are legally required to do so, we will also share your
                information with any authority to prevent cyber crime or fraud
                or to protect the intellectual property of the Treat It System
                or personal safety rights of any individual. This includes
                exchanging information with other companies and organisations
                for the purposes of fraud protection and credit risk reduction.
            </p>

            <h6 className="mt-7">
                5. Payment processing and storage of your personal information
            </h6>
            <p>
                Payment processing services are provided by Stripe Inc. The
                details you provide to us via the Treat It System are passed
                directly to Stripe Inc and will be subject to its terms of use
                and privacy policy by the Stripe Connected Account Agreement,
                available at{" "}
                <a
                    href="https://stripe.com/connect/account-terms"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    https://stripe.com/connect/account-terms
                </a>
                . By inputting payment card details, you are consenting to use
                of the payment card details by Stripe Inc for the purpose of
                paying for your order. If you have any questions relating to
                these services please contact Stripe Inc.
            </p>
            <p>
                For avoidance of doubt, if any payment you initiate using the
                Service does not successfully complete, the payee reserves the
                right to seek payment from you via or outside the Service. Any
                authorization you provide to make repeating automatic payments
                using the Service will remain in effect until canceled. You must
                have appropriate authority to use the payment card that you use
                for payment.
            </p>
            <p>
                Treat It does not store any of your payment card details on its
                systems.
            </p>
            <p>Storage and security of your personal data</p>
            <p>
                Your personal data is stored in electronic and physical records
                maintained by Treat It and/or its service providers in the UK.
                Any medical information or personal data you provide us is
                stored safely and securely on a private database. This database
                is only used by our doctors, customer support team, pharmacy
                team, and a small number of other employees on a need-to-know
                basis, such as IT support. This platform is hosted on our
                servers or third parties servers located at AWS and Redcentric,
                and is based in the UK. Your personal data may be seen by staff
                from outside the EEA who work for us. In this case the data they
                have access to is encrypted and only available via an encrypted
                network. Any data that is not related to your health may be
                transferred and stored outside of the European Economic Area
                (EEA). As some countries may not offer the same level of
                personal data protection as in the EEA in these cases we will
                have a specific agreement with such parties to ensure adequate
                safeguards are in place.
            </p>
            <p>Our promise</p>

            <p>
                The security of all personally identifiable information
                associated with you is taken very seriously and all data
                supplied is treated confidentially. We therefore have several
                security measures in place to try to protect against the loss,
                unauthorized use and corruption of any personal data that is
                under our control. We review our security and privacy policies
                regularly and improve them where it is reasonably possible to do
                so. We do not guarantee that loss, unauthorized use, corruption
                and/or alteration of information will never occur, but we use
                all reasonable efforts to prevent it, including:
            </p>

            <ul>
                <li>having security measures to protect unauthorized access</li>
                <li>
                    making sure your payment transaction and personal data is
                    encrypted using SSL technology.
                </li>
                <li>
                    staff having access to your data have signed a
                    confidentiality agreement
                </li>
                <li>
                    only staff that have a need to know are given access to such
                    data
                </li>
                <li>
                    request all our partners to have to abide to privacy and
                    applicable data protection laws and regulations
                </li>
            </ul>
            <p>
                Please note that the input of data over the internet is never
                fully secure. We cannot, therefore, guarantee the security of
                data you submit via the Website or the Treat It System in
                transit over the internet and any such submission is at entirely
                your own risk. Please ensure that you never leave your device
                logged on or use the Treat It System or the Services in a public
                place where others can potentially see your details.
            </p>
            <p>
                We run our applications in HTTPS secure mode and all textual,
                audio and video information is encrypted.
            </p>

            <h6 className="mt-7">6. Cookies</h6>

            <p>
                Cookies are small pieces of information that are created when
                you visit a website. They are used to store bits of information
                about your interactions with the website, which we can use later
                when processing to make your journey better.
            </p>
            <p>
                We reserve the right to collect technical data about the type of
                browser software or operating system you are using for the
                purposes of tracking website use, or improving the services
                offered through the Treat It System. This information will not
                be used to identify you personally.
            </p>
            <p>
                We may also place a ‘cookie’ on your browser to provide us with
                information about your use of the Website, and to help us
                identify you when you return to the site so that your
                preferences can be stored. Using cookies helps us to improve our
                Website and to deliver a better and more personalized service.
                We may also use cookies to identify repeat visitors.
            </p>
            <p>
                You can remove cookies from your computer at any time and choose
                to disable cookies in your internet browser settings. Without
                cookies enabled, we cannot guarantee that the Website and your
                experience of it are as we intended it to be.
            </p>
            <p>
                The length of time a cookie stays on your device depends on its
                type. We use two types of cookies on the Website, as follows:
            </p>
            <ul>
                <li>
                    Session cookies are temporary cookies which only exist
                    during the time you use the Website (or more strictly, until
                    you close the browser after using the Website). Session
                    cookies help the Website remember what you chose on the
                    previous page, avoiding the need to re-enter information.
                </li>
                <li>
                    Persistent cookies stay on your device after you have
                    visited the Website. For example, when you log onto the
                    Treat It System, a persistent cookie will be used to
                    remember your preferences, so that the system remembers your
                    choice the next time you log in. Persistent cookies help us
                    to identify you as a unique visitor but do not contain any
                    information that could be used to identify you to another
                    person.
                </li>
                <li>
                    We also use web analytics services from other companies to
                    track how visitors reach our site and the path they take
                    through it. These companies use cookies to help us improve
                    our service to you.
                </li>
                <li>
                    You can also learn more about cookies by visiting
                    allaboutcookies.org, which includes additional useful
                    information on cookies. It also shows how to block cookies
                    using various types of browser.
                </li>
            </ul>

            <h6 className="mt-7">7. External Links</h6>
            <p>
                Although the Website only looks to include quality, safe and
                relevant external links users should always adopt a policy of
                caution before clicking any external web links mentioned
                throughout the Website or any part of the Treat It System.
            </p>
            <p>
                If you follow a link to any external web links mentioned
                throughout the Website or any part of the Treat It System,
                please note that these external websites have their own privacy
                policies and that we do not accept any responsibility or
                liability for these policies. Please check these policies before
                you submit any personal information to these websites.
            </p>

            <h6 className="mt-7">8. Social Media Platforms</h6>
            <p>
                Communication, engagement and actions taken through external
                social media platforms that we participate on are subject to the
                terms and conditions as well as the privacy policies of those
                social media platforms.
            </p>
            <p>
                You are advised to use social media platforms wisely and
                communicate/participate on them with due care and caution with
                regard to your personal information. We will never ask for
                personal or sensitive information through social media platforms
                and we encourage users wishing to discuss sensitive details to
                contact the relevant platform provider through primary
                communication channels such as by telephone or email.
            </p>
            <p>
                The Website and the Treat It System may use social sharing
                buttons which help share web content directly from our web pages
                to the social media platform in question. When you use such
                social sharing buttons you do so at your own discretion – you
                should note that the social media platform may track and save
                your request to share a web page respectively through your
                social media platform account. Please note these social media
                platforms have their own privacy policies, and we do not accept
                any responsibility or liability for these policies. Please check
                these policies before you submit any personal information to
                these social media platforms.
            </p>

            <h6 className="mt-6">8.1 Shortened Links in Social Media</h6>
            <p>
                We may through our social media platform accounts share web
                links to relevant web pages. By default some social media
                platforms shorten lengthy URLs.
            </p>
            <p>
                Users are advised to take caution and exercise good judgment
                before clicking on any shortened URLs published on social media
                platforms by us. Despite the best efforts to ensure only genuine
                URLs are published, many social media platforms are prone to
                spam and hacking and therefore we cannot be held liable for any
                damages or implications caused by visiting any shortened links.
            </p>

            <h6 className="mt-7">9. Your rights</h6>
            <p>Objection to processing of your data.</p>
            <p>
                You may ask us not to process your data for marketing purposes.
                We will let you know prior to collecting any data for marketing
                or if we intend to disclose your information to any third party.
                You can deny this use of data when you register with us or by
                changing this in your account at a later date or by selecting
                unsubscribe which can be found on any marketing email that we
                send to you. You may also contact us at any time and let us know
                of your preferences on the contact details provided at the end
                of this notice.
            </p>
            <p>Correcting your data.</p>
            <p>
                You are able to update and correct any information that may be
                incorrect by logging into your account. If we have made an error
                you wish to change but are unable to do so, you can also contact
                our support team at any time.
            </p>
            <p>Deleting your data</p>
            <p>
                You may request deletion of your account on the App or the Web
                Ordering Tool and information submitted and collected at any
                time by contacting:
            </p>

            <div className="my-8">
                <p className="mb-1">TREAT IT</p>
                <p className="mb-1">C/O The GP Service (UK) Limited</p>
                <p className="mb-1">Coventry University Technology Park,</p>
                <p className="mb-1">The TechnoCentre,</p>
                <p className="mb-1">Puma Way,</p>
                <p className="mb-1">COVENTRY,</p>
                <p className="mb-1">CV1 2TT</p>
                <p className="mb-1">{SUPPORT_EMAIL}</p>
            </div>
            <p>
                However, we may retain certain aspects of your account and other
                personal details for the purposes of maintaining of medical
                records in our dealings with you including for, medical auditing
                analysis and statistics. Such data is required to be continued
                to be held by us for a minimum period as described in 3.1 above
                and cannot be deleted, but you can ask us to disable your
                account by contacting us. You will be sent a separate form to
                complete.
            </p>

            <p>
                Treat It may delete your account in accordance with clauses 5.4
                and 14.2 of Treat It’s Terms and Conditions available on our
                website, including in the circumstances where you breach its
                terms or have not used the Services for a substantial period of
                time.
            </p>

            <h6 className="mt-7">10. Access to your personal data</h6>
            <p>
                You have the right to see any information we hold about you. If
                you would like to access a copy of any personal data which Treat
                It holds about you, or if you wish to change your consent,
                please send a request by email or by post using the contact
                details below. Please let us know what information you would
                like to access. You will be required to provide us with two
                types of ID, one with photo such as a passport or driving
                license and one with a proof of address such as utility bill
                that is less than 3 months old.
            </p>
            <p>Consent Preferences</p>
            <p>
                Should at any time you wish to change or withdraw your consent
                preferences, please contact us on the email provided at the end
                of this notice or change your preferences within your online
                account.
            </p>
            <p>Please contact us:</p>
            <div className="my-8">
                <p className="mb-1">TREAT IT</p>
                <p className="mb-1">C/O The GP Service (UK) Limited</p>
                <p className="mb-1">Coventry University Technology Park,</p>
                <p className="mb-1">The TechnoCentre,</p>
                <p className="mb-1">Puma Way,</p>
                <p className="mb-1">COVENTRY,</p>
                <p className="mb-1">CV1 2TT</p>
                <p className="mb-1">{SUPPORT_EMAIL}</p>
            </div>

            <h6 className="mt-7">11. Queries</h6>
            <p>
                If you have any queries in relation to the processing of your
                personal data by the Treat It System Limited, please contact us:
            </p>
            <ul>
                <li>
                    <span className="fw-bold">By post: </span> Treat It, c/o the
                    GP Service (UK) Limited, Coventry University Technology
                    Park, The TechnoCentre, Puma Way, COVENTRY, CV1 2TT
                </li>
                <li>
                    <span className="fw-bold">By email: </span> {SUPPORT_EMAIL}
                </li>
                <li>
                    <span className="fw-bold">By phone: </span> Please ask for
                    the Admin Team between the hours of 9.30am and 5.00pm Monday
                    to Thursday on {SUPPORT_TELEPHONE}.
                </li>
            </ul>

            <h6 className="mt-7">12. Changes to this privacy notice</h6>
            <p>
                Privacy laws and practice are constantly developing and we aim
                to meet high standards. Our policies and procedures are,
                therefore, under continual review. We may, from time to time,
                update our security and privacy policies and suggest that you
                check this page periodically to review our latest policies.
            </p>
        </div>
    );
}

export default PrivacyPolicyPage;
